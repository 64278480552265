import { MissionType } from "../../types";

const arkSociety: MissionType = {
  location: "Isle of Sgàil",
  title: "The Ark Society",
  version: 2,
  targets: ["Zoe Washington", "Sophia Washington"],
  disguises: [
    { name: "Tuxedo and Mask", outfit: false },
    { name: "Architect" },
    { name: "Ark Member" },
    { name: "Blake Nathaniel" },
    { name: "Burial Robes" },
    { name: "Butler" },
    { name: "Castle Staff" },
    { name: "Chef" },
    { name: "Custodian" },
    { name: "Elite Guard" },
    { name: "Entertainer" },
    { name: "Event Staff" },
    { name: "Guard" },
    { name: "Initiate" },
    { name: "Jebediah Block" },
    { name: "Knight's Armor" },
    { name: "Master of Ceremonies" },
    { name: "Raider" },
  ],
  weapons: [
    { name: "Aztec Necklace" },
    { name: "Battle Axe" },
    { name: "Burial Dagger" },
    { name: "Circumcision Knife" },
    { name: "Cleaver" },
    { name: "Fire Poker" },
    { name: "Hatchet" },
    { name: "Katana" },
    { name: "Kitchen Knife" },
    { name: "Letter Opener" },
    { name: "Old Axe" },
    { name: "Saber" },
    { name: "Sacrificial Knife" },
    { name: "Sapper's Axe" },
    { name: "Scalpel" },
    { name: "Screwdriver" },
    { name: "Viking Axe" },
  ],
};

export default arkSociety;
