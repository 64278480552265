import { MissionType } from "../../types";

const worldOfTomorrow: MissionType = {
  location: "Sapienza",
  title: "World of Tomorrow",
  version: 1,
  targets: ["Silvio Caruso", "Francesca De Santis"],
  disguises: [
    { name: "Italian Suit", outfit: false },
    { name: "Biolab Security" },
    { name: "Bodyguard" },
    { name: "Bohemian" },
    { name: "Butler" },
    { name: "Church Staff" },
    { name: "Cyclist" },
    { name: "Delivery Man" },
    { name: "Dr. Oscar Lafayette" },
    { name: "Gardener" },
    { name: "Green Plumber" },
    { name: "Hazmat Suit" },
    { name: "Housekeeper" },
    { name: "Kitchen Assistant" },
    { name: "Lab Technician" },
    { name: "Mansion Chef" },
    { name: "Mansion Security" },
    { name: "Mansion Staff" },
    { name: "Plague Doctor" },
    { name: "Priest" },
    { name: "Private Detective" },
    { name: "Red Plumber" },
    { name: "Roberto Vargas" },
    { name: "Store Clerk" },
    { name: "Street Performer" },
    { name: "Waiter" },
  ],
  weapons: [
    { name: "Amputation Knife" },
    { name: "Battle Axe" },
    { name: "Circumcision Knife" },
    { name: "Combat Knife" },
    { name: "Fire Axe" },
    { name: "Fire Poker" },
    { name: "Folding Knife" },
    { name: "Hatchet" },
    { name: "Katana" },
    { name: "Kitchen Knife" },
    { name: "Letter Opener" },
    { name: "Old Axe" },
    { name: "Saber" },
    { name: "Scissors" },
    { name: "Screwdriver" },
  ],
  wildcards: [
    {
      name: "Just Like Momma Used to Make",
      description: "Poison someone with Expired Spaghetti Sauce",
    },
    {
      name: "Do It the Hard Way",
      description: "Eliminate virus without using laptops",
    },
  ],
};

export default worldOfTomorrow;
